// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------
body {
  position: relative;
}

.content-section-row {
  position: relative;
}

.bg-img-fill {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &._object-top img {
    object-position: top;
  }

  &._object-right img {
    object-position: right;
  }

  &._object-bottom img {
    object-position: bottom;
  }

  &._object-left img {
    object-position: left;
  }

  &+div {
    position: relative;
    z-index: 2;
  }

  img {
    display: block;
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    font-family: 'object-fit: cover;';
  }
}

.banner-section {
  position: relative;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    height: 72%;
    background-color: #282939;
    content: "";
  }
}

.brand-list {
  >li {
    width: 50%;
    padding: 0 15px;
    margin-bottom: 30px;

    &:last-child {
      margin-right: 0;
    }

    img {
      display: block;
      max-width: 100%;
    }
  }

  @media(min-width: 576px) {
    >li {
      padding: 0;
      margin-right: 80px;
      width: auto;
    }
  }
}

.map-container {
  position: relative;

  &:before {
    position: absolute;
    top: 25px;
    left: -25px;
    width: calc(100% + 105px);
    height: 2px;
    background-color: #E0E0E2;
    content: "";
    transform: rotate(26deg);
    transform-origin: top left;
  }

  .btn {
    position: relative;
    max-width: 430px;
    width: 100%;
    z-index: 1;
    padding: 12px 15px;
    border-radius: 0;
    font-weight: 600;

    i {
      margin-left: 15px;
      font-size: 12px;
    }
  }
}

#map-canvas {
  position: relative;
  z-index: 1;
  height: 450px;
  box-shadow: -5px 5px 3px 0px rgba(0, 0, 0, .21);
}

.social-links {
  a {
    &:hover,
    &:focus {
      color: var(--primary);
    }
  }
}