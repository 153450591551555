// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------

.footer {
    .footer-body {
        padding-top: 50px;
        background-color: #282939;
        color: #fff;
    }

    .contact-list {
        .list-item {
            display: flex;
            align-items: flex-start;
            margin-bottom: 20px;

            .item-img {
                margin-right: 15px;
                width: 30px;
                text-align: center;
            }
        }

        .social-links {
            >li {
                margin-right: 15px;

                a {
                    font-size: 24px;
                }
            }
        }
    }

    .copyright {
        padding: 25px 15px;
        background-color: #EDE6E0;
        color: #141414;
        font-size: 12px;
        text-align: center;
        text-transform: uppercase;
    }

    @media(min-width: 992px) {
        .copyright {
            padding: 20px 15px;
            font-size: 13px;
        }
    }
}