// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
.header {
    padding: 40px 15px;
    background-color: #282939;

    .header-brand {
        padding: 0 70px;
    }

    .social-links {
        position: absolute;
        right: 0;
        bottom: 0;
        margin: 0;
        color: #fff;

        >li {
            a {
                display: block;
                padding: 2px 8px;
            }
        }
    }

    @media(min-width: 576px) {
        .header-brand {
            padding: 0 90px;

            img {
                width: 350px;
            }
        }

        .social-links {
            bottom: 30px;

            >li a {
                font-size: 24px;
            }
        }
    }

    @media(min-width: 768px) {}

    @media(min-width: 992px) {
        .header-brand img {
            width: 445px;
        }
    }
}