.qty-input-component {
    display: inline-flex;
    align-items: center;
    border: 1px solid var(--primary);
    border-radius: 10px;

    .btn,
    .qty-input {
        &:focus {
            outline: none;
            box-shadow: none;
        }
    }

    .qty-input {
        width: 50px;
        border: 0;
        text-align: center;
        font-weight: 700;
        color: var(--primary);
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }
}

.form-check-theme,
.form-radio-theme {
    position: relative;

    input {
        display: none;
    }

    .input-label {
        position: relative;
        display: block;
        padding-left: 35px;
        font-size: 14px;
        color: var(--primary);

        &:before,
        &:after {
            position: absolute;
            left: 0;
            top: 0;
            content: "";
        }

        &:before {
            border-radius: 6px;
            border: 1px solid var(--primary);
            height: 20px;
            width: 20px;
        }
    }
}

.form-check-theme {
    .input-label {
        &:after {
            @include fontawesome;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 20px;
            font-weight: 400;
            content: "\f00c";
            color: #fff;
            opacity: 0;
        }
    }

    input:checked~.input-label {
        &:before {
            background-color: var(--primary);
        }

        &:after {
            opacity: 1;
        }
    }
}

.form-radio-theme {
    .input-label {
        &:before {
            border-radius: 50%;
        }
    }

    input:checked~.input-label {
        &:before {
            background-color: var(--primary);
            outline: 1px solid #fff;
            outline-offset: -3px;
        }
    }
}