.navbar {
  background-color: #f8f8fa; }
  .navbar .navbar-toggler {
    margin-right: -5px;
    border: 0;
    color: var(--primary); }
    .navbar .navbar-toggler.collapsed {
      color: #585858; }
    .navbar .navbar-toggler .navbar-toggler-icon {
      width: auto;
      height: auto;
      background-image: none;
      font-size: 26px; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../vendors/slick/ajax-loader.gif") center center no-repeat; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  z-index: 2;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  cursor: pointer;
  font-size: 0;
  line-height: 0;
  color: transparent;
  border: none;
  outline: none;
  background: transparent; }

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent; }

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1; }

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: .25; }

.slick-prev:before,
.slick-next:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  font-size: 20px;
  color: #ddd; }

.slick-prev {
  left: -25px; }

[dir='rtl'] .slick-prev {
  right: -25px;
  left: auto; }

.slick-prev:before {
  content: "\f137"; }

[dir='rtl'] .slick-prev:before {
  content: "\f138"; }

.slick-next {
  right: -25px; }

[dir='rtl'] .slick-next {
  right: auto;
  left: -25px; }

.slick-next:before {
  content: "\f138"; }

[dir='rtl'] .slick-next:before {
  content: "\f137"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center; }

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer; }

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent; }

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none; }

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1; }

.slick-dots li button:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  font-size: 12px;
  color: #ddd;
  content: "\f111"; }

.slick-dots li.slick-active button:before {
  opacity: .75;
  color: black; }

.slick-slider .slick-slide:focus {
  outline: none; }

.slick-slider-full .slick-list,
.slick-slider-full .slick-track,
.slick-slider-full .slide-item,
.slick-slider-full .bg-img-fill {
  height: 100%; }

.slick-slider-full .slide-item {
  position: relative; }
  .slick-slider-full .slide-item:focus {
    outline: none; }

body {
  font-family: 'Lato', sans-serif; }

a {
  color: inherit;
  text-decoration: none; }
  a:hover {
    color: inherit;
    text-decoration: underline; }

.btn,
a {
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out; }

input[type="email"],
input[type="password"],
input[type="text"],
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-focus-ring-color: none;
  outline: none; }

textarea {
  resize: none; }

iframe {
  display: block; }

.ff-cg, .text-title {
  font-family: 'Cormorant Garamond', serif; }

[data-aos=zoom-in] {
  transform: scale(0.95); }

[data-aos=fade-up] {
  transform: translate3d(0, 50px, 0); }

[data-aos=fade-down] {
  transform: translate3d(0, -50px, 0); }

[data-aos=fade-left] {
  transform: translate3d(50px, 0, 0); }

[data-aos=fade-right] {
  transform: translate3d(-50px, 0, 0); }

.aos {
  opacity: 0; }

.text-underline {
  text-decoration: underline; }

.fw-thin {
  font-weight: 100 !important; }

.fw-light {
  font-weight: 300 !important; }

.fw-normal {
  font-weight: 400 !important; }

.fw-medium {
  font-weight: 500 !important; }

.fw-semibold {
  font-weight: 600 !important; }

.fw-bold {
  font-weight: 700 !important; }

.fw-extrabold {
  font-weight: 800 !important; }

.fw-black {
  font-weight: 900 !important; }

.lh-1 {
  line-height: 1; }

.lh-2 {
  line-height: 2; }

.text-gray {
  color: #938F8C; }

.text-gray-1 {
  color: #6F6A65; }

button:focus,
.btn:focus {
  outline: none; }

.scroll-top-btn {
  position: fixed;
  right: 5px;
  bottom: 5px;
  -webkit-transform: translate(0, 50px);
  -moz-transform: translate(0, 50px);
  -o-transform: translate(0, 50px);
  -ms-transform: translate(0, 50px);
  transform: translate(0, 50px);
  z-index: 999;
  width: auto;
  background-color: #000;
  color: #fff;
  -webkit-transition: all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94); }
  .scroll-top-btn:hover {
    color: var(--primary); }
  .scroll-top-btn .fa {
    font-size: 14px; }
  .scroll-top-btn.reveal-btn {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0); }
  @media (min-width: 575px) {
    .scroll-top-btn {
      bottom: 10px;
      right: 20px; } }

.qty-input-component {
  display: inline-flex;
  align-items: center;
  border: 1px solid var(--primary);
  border-radius: 10px; }
  .qty-input-component .btn:focus,
  .qty-input-component .qty-input:focus {
    outline: none;
    box-shadow: none; }
  .qty-input-component .qty-input {
    width: 50px;
    border: 0;
    text-align: center;
    font-weight: 700;
    color: var(--primary); }
  .qty-input-component input::-webkit-outer-spin-button,
  .qty-input-component input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  .qty-input-component input[type=number] {
    -moz-appearance: textfield; }

.form-check-theme,
.form-radio-theme {
  position: relative; }
  .form-check-theme input,
  .form-radio-theme input {
    display: none; }
  .form-check-theme .input-label,
  .form-radio-theme .input-label {
    position: relative;
    display: block;
    padding-left: 35px;
    font-size: 14px;
    color: var(--primary); }
    .form-check-theme .input-label:before, .form-check-theme .input-label:after,
    .form-radio-theme .input-label:before,
    .form-radio-theme .input-label:after {
      position: absolute;
      left: 0;
      top: 0;
      content: ""; }
    .form-check-theme .input-label:before,
    .form-radio-theme .input-label:before {
      border-radius: 6px;
      border: 1px solid var(--primary);
      height: 20px;
      width: 20px; }

.form-check-theme .input-label:after {
  font-family: "Font Awesome 5 Pro";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  font-weight: 400;
  content: "\f00c";
  color: #fff;
  opacity: 0; }

.form-check-theme input:checked ~ .input-label:before {
  background-color: var(--primary); }

.form-check-theme input:checked ~ .input-label:after {
  opacity: 1; }

.form-radio-theme .input-label:before {
  border-radius: 50%; }

.form-radio-theme input:checked ~ .input-label:before {
  background-color: var(--primary);
  outline: 1px solid #fff;
  outline-offset: -3px; }

.header {
  padding: 40px 15px;
  background-color: #282939; }
  .header .header-brand {
    padding: 0 70px; }
  .header .social-links {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 0;
    color: #fff; }
    .header .social-links > li a {
      display: block;
      padding: 2px 8px; }
  @media (min-width: 576px) {
    .header .header-brand {
      padding: 0 90px; }
      .header .header-brand img {
        width: 350px; }
    .header .social-links {
      bottom: 30px; }
      .header .social-links > li a {
        font-size: 24px; } }
  @media (min-width: 992px) {
    .header .header-brand img {
      width: 445px; } }

.footer .footer-body {
  padding-top: 50px;
  background-color: #282939;
  color: #fff; }

.footer .contact-list .list-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px; }
  .footer .contact-list .list-item .item-img {
    margin-right: 15px;
    width: 30px;
    text-align: center; }

.footer .contact-list .social-links > li {
  margin-right: 15px; }
  .footer .contact-list .social-links > li a {
    font-size: 24px; }

.footer .copyright {
  padding: 25px 15px;
  background-color: #EDE6E0;
  color: #141414;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase; }

@media (min-width: 992px) {
  .footer .copyright {
    padding: 20px 15px;
    font-size: 13px; } }

body {
  position: relative; }

.content-section-row {
  position: relative; }

.bg-img-fill {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  .bg-img-fill._object-top img {
    object-position: top; }
  .bg-img-fill._object-right img {
    object-position: right; }
  .bg-img-fill._object-bottom img {
    object-position: bottom; }
  .bg-img-fill._object-left img {
    object-position: left; }
  .bg-img-fill + div {
    position: relative;
    z-index: 2; }
  .bg-img-fill img {
    display: block;
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    font-family: 'object-fit: cover;'; }

.banner-section {
  position: relative; }
  .banner-section:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    height: 72%;
    background-color: #282939;
    content: ""; }

.brand-list > li {
  width: 50%;
  padding: 0 15px;
  margin-bottom: 30px; }
  .brand-list > li:last-child {
    margin-right: 0; }
  .brand-list > li img {
    display: block;
    max-width: 100%; }

@media (min-width: 576px) {
  .brand-list > li {
    padding: 0;
    margin-right: 80px;
    width: auto; } }

.map-container {
  position: relative; }
  .map-container:before {
    position: absolute;
    top: 25px;
    left: -25px;
    width: calc(100% + 105px);
    height: 2px;
    background-color: #E0E0E2;
    content: "";
    transform: rotate(26deg);
    transform-origin: top left; }
  .map-container .btn {
    position: relative;
    max-width: 430px;
    width: 100%;
    z-index: 1;
    padding: 12px 15px;
    border-radius: 0;
    font-weight: 600; }
    .map-container .btn i {
      margin-left: 15px;
      font-size: 12px; }

#map-canvas {
  position: relative;
  z-index: 1;
  height: 450px;
  box-shadow: -5px 5px 3px 0px rgba(0, 0, 0, 0.21); }

.social-links a:hover, .social-links a:focus {
  color: var(--primary); }
