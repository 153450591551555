// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
button,
.btn {
  &:focus {
    outline: none;
  }
}

// scroll-top-btn
.scroll-top-btn {
  position: fixed;
  right: 5px;
  bottom: 5px;
  @include translate(0, 50px);
  z-index: 999;
  width: auto;
  background-color: #000;
  color: #fff;
  @include transition(.35s);

  &:hover {
    color: $primary-color;
  }

  .fa {
    font-size: 14px;
  }

  &.reveal-btn {
    @include translate(0, 0);
  }

  @media(min-width: 575px) {
    bottom: 10px;
    right: 20px;
  }
}