[data-aos=zoom-in] {
  transform: scale(.95);
}

[data-aos=fade-up] {
  transform: translate3d(0, 50px, 0);
}

[data-aos=fade-down] {
  transform: translate3d(0, -50px, 0);
}

[data-aos=fade-left] {
  transform: translate3d(50px, 0, 0);
}

[data-aos=fade-right] {
  transform: translate3d(-50px, 0, 0);
}

.aos {
  opacity: 0;
}

.text-underline {
  text-decoration: underline;
}

.fw-thin {
  font-weight: 100 !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-extrabold {
  font-weight: 800 !important;
}

.fw-black {
  font-weight: 900 !important;
}

.lh-1 {
  line-height: 1;
}

.lh-2 {
  line-height: 2
}

.text-title {
  @extend .ff-cg;
}

.text-gray {
  color: #938F8C;
}

.text-gray-1 {
  color: #6F6A65;
}